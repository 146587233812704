<!-- resource-edit -->
<template>
  <el-dialog
    class="resource-edit"
    :visible.sync="open"
    :title="title"
    width="560px"
    :close-on-click-modal="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <el-form
      class="resource-edit-form"
      ref="form"
      v-loading="loading"
      :model="model"
      :rules="rules"
      size="small"
      label-width="85px"
      @submit.native.prevent
    >
      <el-form-item label="资源类型:" prop="type">
        <resource-type
          v-model="model.type"
          @on-change="handleResourceTypeChange"
        />
      </el-form-item>

      <el-form-item label="资源名称:" prop="name">
        <el-input v-model.trim="model.name" placeholder="请输入" clearable />
      </el-form-item>

      <el-form-item label="资源值:" prop="value">
        <el-input v-model.trim="model.value" placeholder="请输入" clearable />
      </el-form-item>

      <el-form-item label="上级资源:">
        <resource
          v-if="whetherRenderResourceCom"
          ref="resource"
          v-model="model.pid"
          check-strictly
          :hidden-callback="resourceTreeHiddenCallback"
          @on-ready="handleResourceComReady"
        />
      </el-form-item>

      <el-form-item
        v-if="model.type === resourceTypeEnum.menu"
        label="可见性:"
        prop="visible"
      >
        <el-radio-group v-model="model.visible">
          <el-radio
            v-for="item in resourceVisibleList"
            :label="item.value"
            :key="item.value"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>

      <el-form-item label="排序号:" prop="sortNo">
        <el-input
          v-model.number="model.sortNo"
          placeholder="请输入"
          clearable
        />
      </el-form-item>
    </el-form>

    <template v-slot:footer>
      <div class="resource-edit-operate">
        <el-button size="small" @click="handleOperateCancelClick"
          >取消
        </el-button>

        <el-button
          type="primary"
          size="small"
          :loading="saving"
          @click="handleOperateOkClick"
          >确定
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
  import Resource from '../../../../components/resource';
  import ResourceType from '../../../../components/resource-type';
  import resourceType from '../../../../enum/resource-type';
  import resourceVisible from '../../../../enum/resource-visible';
  import { isNonNegativeIntString } from '../../../../util/validator';
  import {
    getResource,
    addResource,
    updateResource,
  } from '../../../../api/sys/resource';

  // 递归获取资源中指定节点的全部后代节点
  const recursiveChildren = (node, nodeList, arr = []) => {
    if (!node || !Array.isArray(nodeList) || !nodeList.length) return [];

    const { _id } = node;
    const children = nodeList.filter(item => item.pid === _id);

    if (!children || !children.length) return arr;

    arr.push(...children);

    children.forEach(child => recursiveChildren(child, nodeList, arr));

    return arr;
  };

  // 非负整数校验
  const nonNegativeInteger = (rule, value, callback) => {
    if (!value) callback();

    isNonNegativeIntString(value)
      ? callback()
      : callback(new Error('请填写非负整数'));
  };

  export default {
    name: 'resource-edit',
    components: {
      ResourceType,
      Resource,
    },
    props: {
      // 是否开启对话框
      value: {
        type: Boolean,
        default: false,
      },
      // 资源 id
      id: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        // 是否渲染 resource 组件，其需要在每次渲染时获取最新的全量资源列表
        whetherRenderResourceCom: false,
        // resource 组件获取到的全量资源列表
        resourceComList: [],
        // 当前编辑的资源对象
        resource: null,
        model: null,
        rules: {
          type: [{ required: true, message: '请选择', trigger: 'change' }],
          name: [{ required: true, message: '请填写', trigger: 'change' }],
          value: [{ required: true, message: '请填写', trigger: 'change' }],
          visible: [{ required: true, message: '请选择', trigger: 'change' }],
          sortNo: [{ validator: nonNegativeInteger, trigger: 'change' }],
        },
        loading: false,
        saving: false,
      };
    },
    computed: {
      open: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      // 是否是编辑
      isEdit() {
        return !!this.id;
      },
      // 对话框标题
      title() {
        return `${this.isEdit ? '编辑' : '新增'}资源`;
      },
      // 资源类型枚举值
      resourceTypeEnum() {
        return resourceType.enum;
      },
      // 资源可见性列表
      resourceVisibleList() {
        const { map } = resourceVisible;

        return Object.entries(map).map(([value, key]) => ({
          label: key,
          value: +value,
        }));
      },
      // 当前编辑的节点及其全部后代节点
      editNodeAndDescendantNodes() {
        const currNode = this.resource;

        if (!this.isEdit || !currNode) return [];

        return [currNode, ...recursiveChildren(currNode, this.resourceComList)];
      },
    },
    created() {
      this.initModel();
    },
    methods: {
      // 初始化模型
      initModel(data = {}) {
        const {
          type = resourceType.enum.menu,
          name = '',
          value = '',
          pid = '',
          visible = resourceVisible.enum.visible,
          sortNo = '',
        } = data || {};

        this.model = {
          type,
          name,
          value,
          pid,
          visible,
          sortNo,
        };
      },
      // 校验模型
      async validateModel() {
        try {
          return await this.$refs.form.validate();
        } catch (e) {
          return false;
        }
      },
      // 重置模型
      resetModel() {
        this.initModel();
        this.$refs.form.resetFields();
      },
      // 保存模型
      async saveModel() {
        const params = {
          ...this.model,
          id: this.id,
        };
        const method = this.isEdit ? updateResource : addResource;
        const res = await method(params);

        return !!res;
      },
      // 资源组件不显示相应节点的回调函数
      resourceTreeHiddenCallback(resourceNode) {
        const { _id, type } = resourceNode;
        const isHiddenNode = this.editNodeAndDescendantNodes.some(
          item => item._id === _id
        );
        const isButtonNode = type === resourceType.enum.button;

        return isHiddenNode || isButtonNode;
      },
      // 获取资源对象详情
      async getResource() {
        const res = await getResource(this.id);

        if (!res) return false;

        this.resource = res.data;

        return true;
      },
      // 资源类型改变
      handleResourceTypeChange() {
        // 重置 visible 为默认值
        this.model.visible = resourceVisible.enum.visible;
      },
      // 对话框打开
      async handleDialogOpen() {
        this.loading = true;

        // 如果是编辑，则先获取资源对象
        this.isEdit && (await this.getResource());
        // 重新渲染 resource 组件，确保其数据是最新的(与资源列表相匹配)
        this.whetherRenderResourceCom = true;

        this.initModel(this.resource);
      },
      // 资源组件就绪
      async handleResourceComReady(resourceTree, resourceList) {
        this.resourceComList = resourceList;
        this.loading = false;
      },
      // 对话框关闭完成
      handleDialogClosed() {
        this.whetherRenderResourceCom = false;
        this.resourceComList = [];
        this.resource = null;

        this.resetModel();
      },
      // 对话框取消按钮单击
      handleOperateCancelClick() {
        this.open = false;
      },
      // 对话框确定按钮单击
      async handleOperateOkClick() {
        const isValid = await this.validateModel();

        if (!isValid) return;

        this.saving = true;

        const success = await this.saveModel();

        this.saving = false;

        if (!success) return;

        this.open = false;
        this.$emit('on-ok');
      },
    },
  };
</script>

<style scoped lang="scss">
  .resource-edit {
    .resource-edit-form {
      .el-select,
      .el-input {
        width: 100%;
      }
    }
  }
</style>
