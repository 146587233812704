<!-- resource-tree -->
<template>
  <div class="resource-tree" :class="{ filterable }">
    <!-- 搜索框 -->
    <div class="resource-tree-search" v-if="filterable">
      <el-input
        v-model="keyword"
        size="small"
        :placeholder="filterPlaceholder"
        clearable
        @input="handleSearchInput"
      />
    </div>

    <!-- 资源树 -->
    <div class="resource-tree-container" :style="resourceTreeContainerStyle">
      <el-scrollbar class="tree-container-scrollbar">
        <el-tree
          ref="tree"
          :data="formattedResourceTree"
          :props="treeOptions"
          :node-key="valueKey"
          :empty-text="placeholder"
          :show-checkbox="checkable"
          :check-strictly="checkStrictly"
          :default-expand-all="defaultExpandAll"
          :filter-node-method="filterMethod || resourceTreeFilterNodeFn"
          :highlight-current="highlightCurrent"
          :expand-on-click-node="expandOnClickNode"
          @node-click="handleNodeClick"
          @check-change="handleNodeCheckChange"
          @check="handleNodeCheckClick"
        />
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
  import tree from '../../../mixins/tree';
  import genResourceTree from '../../../lib/gen-resource-tree';
  import { getResources } from '../../../api/sys/resource';

  // 递归格式化各个节点
  const recursiveFormat = (nodes, keyMaps) => {
    if (!Array.isArray(nodes) || !nodes.length) return [];

    const {
      labelKey,
      valueKey,
      childrenKey,
      disabledKey,
      disabledCallback,
      hiddenCallback,
    } = keyMaps;

    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];

      // 根据 hiddenCallback 选项判断是否隐藏
      if (hiddenCallback instanceof Function && hiddenCallback(node)) {
        nodes.splice(i, 1);
        i--;

        continue;
      }

      const { _id, name = '', children = [] } = node;
      const formattedChildren = recursiveFormat(children, keyMaps);
      const formattedNode = {
        [labelKey]: name,
        [valueKey]: _id,
        [childrenKey]: formattedChildren.length ? formattedChildren : null,
        [disabledKey]: false,
      };
      const combinedNode = {
        ...node,
        ...formattedNode,
      };

      // 根据 disabledCallback 选项判断是否禁用
      formattedNode[disabledKey] =
        disabledCallback instanceof Function
          ? disabledCallback(combinedNode)
          : false;

      nodes[i] = combinedNode;
    }

    return nodes;
  };

  export default {
    name: 'resource-tree',
    mixins: [tree],
    data() {
      return {
        keyword: '',
        resourceList: [],
        resourceTreeReady: false,
      };
    },
    computed: {
      // 树形选择器配置对象
      treeOptions() {
        const { labelKey, childrenKey, disabledKey } = this;

        return {
          label: labelKey,
          children: childrenKey,
          disabled: disabledKey,
        };
      },
      // 资源树容器样式
      resourceTreeContainerStyle() {
        const maxHeight = this.maxHeight;

        return {
          maxHeight: maxHeight ? `${maxHeight}px` : 'auto',
        };
      },
      resourceTree() {
        return genResourceTree(this.resourceList);
      },
      formattedResourceTree() {
        const {
          resourceTree,
          labelKey,
          valueKey,
          childrenKey,
          disabledKey,
          disabledCallback,
          hiddenCallback,
        } = this;

        return Array.isArray(resourceTree) && resourceTree.length
          ? recursiveFormat(resourceTree, {
              labelKey,
              valueKey,
              childrenKey,
              disabledKey,
              disabledCallback,
              hiddenCallback,
            })
          : [];
      },
      clonedResourceTree() {
        return JSON.parse(JSON.stringify(this.resourceTree));
      },
    },
    mounted() {
      this.initResourceTree();
    },
    methods: {
      // 刷新数据，供父组件调用
      async refresh() {
        return await this.getResourceList();
      },
      async getResourceList() {
        const params = { pageSize: 0 };
        const res = await getResources(params);

        if (!res) return false;

        const { data: { list = [] } = {} } = res;

        this.resourceList = list || [];

        return true;
      },
      // 初始化资源树
      async initResourceTree() {
        const success = await this.getResourceList();

        if (!success) return;

        this.resourceTreeReady = true;

        this.setResourceTreeChecked();
        this.$emit('on-ready', this.clonedResourceTree);
      },
      // 设置资源树选中节点
      setResourceTreeChecked() {
        const checkedIds = this.value || [];
        const treeCom = this.$refs.tree;

        // 当 checkedIds 为空时清空勾选
        if (!checkedIds.length) {
          treeCom.setCheckedKeys([]);

          return;
        }

        // 解决 el-tree 半选回填的 bug
        checkedIds.forEach(id => {
          const treeNode = treeCom.getNode(id);

          if (!treeNode.isLeaf) return;

          treeCom.setChecked(treeNode, true);
        });
      },
      // 资源树过滤节点的函数
      resourceTreeFilterNodeFn(value, data, node) {
        if (!value || !data) return true;

        const label = data[this.labelKey];

        return label.includes(value);
      },
      // 搜索框输入
      handleSearchInput(val) {
        this.$refs.tree.filter(val);
      },
      // 节点单击
      handleNodeClick(nodeData) {
        this.$emit('on-node-click', nodeData);
      },
      // 节点选中状态改变
      handleNodeCheckChange(nodeData, isChecked) {
        this.$emit('on-node-check-change', nodeData, isChecked);
      },
      // 节点复选框单击，该事件中可以获取到整个树的选中状态
      handleNodeCheckClick(nodeData, checkedStatus) {
        const { checkedNodes, checkedKeys, halfCheckedNodes, halfCheckedKeys } =
          checkedStatus;
        const allCheckedKeys = [...halfCheckedKeys, ...checkedKeys];
        const allCheckedNodes = [...halfCheckedNodes, ...checkedNodes];

        this.$emit('on-node-check-click', nodeData);
        this.$emit('input', allCheckedKeys);
        this.$emit('on-change', allCheckedKeys, allCheckedNodes);
      },
    },
    watch: {
      value: {
        handler() {
          this.resourceTreeReady && this.setResourceTreeChecked();
        },
      },
    },
  };
</script>

<style scoped lang="scss">
  .resource-tree {
    // 资源树
    .resource-tree-container {
      display: flex;
      border: 1px solid #e4e7ed;
      border-radius: 4px;
      overflow: hidden;

      /deep/ .tree-container-scrollbar {
        flex-grow: 1;

        .el-scrollbar__wrap {
          height: 100%;
          padding: 5px 0;
          overflow: hidden auto;
        }
      }
    }

    &.filterable {
      padding: 10px;
      border: 1px solid #dcdfe6;
      border-radius: 4px;

      .resource-tree-container {
        margin-top: 10px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }
</style>
