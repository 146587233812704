// 根据资源列表生成资源树(树形结构)
const genResourceTree = (resourceList, resourceTree = []) => {
  if (!Array.isArray(resourceList) || !resourceList.length) return resourceTree;

  const clonedResourceList = JSON.parse(JSON.stringify(resourceList));

  clonedResourceList.forEach(resource => {
    const { pid } = resource;

    if (!pid) {
      resourceTree.push(resource);

      return;
    }

    const pResource = clonedResourceList.find(resource => resource._id === pid);

    if (!pResource) return;

    const { children = [] } = pResource;

    pResource.children = [...children, resource];
  });

  return resourceTree;
};

export default genResourceTree;
