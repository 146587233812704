<!-- resource -->
<template>
  <component
    class="resource"
    ref="resource"
    :is="dynamicCom"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
  import ResourceCascade from './resource-cascade';
  import ResourceTree from './resource-tree';

  export default {
    name: 'resource',
    props: {
      // 组件形态，可选值: cascade => 级联列表，tree => 树，默认值: cascade
      type: {
        type: String,
        validator: val => ['cascade', 'tree'].includes(val),
        default: 'cascade',
      },
    },
    computed: {
      dynamicCom() {
        return this.type === 'cascade' ? ResourceCascade : ResourceTree;
      },
    },
    methods: {
      // 刷新数据，供父组件调用
      async refresh() {
        return await this.$refs.resource.refresh();
      },
    },
  };
</script>

<style scoped lang="scss">
  .resource {
    width: 100%;
  }
</style>
