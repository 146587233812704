// 用于 tree 的 mixin
export default {
  props: {
    // 选中项 value
    value: {
      type: Array,
      default: '',
    },
    // 空占位符文案
    placeholder: {
      type: String,
      default: '暂无数据',
    },
    // 节点是否可被选中(是否显示复选框)
    checkable: {
      type: Boolean,
      default: true,
    },
    // 是否可选任意节点
    checkStrictly: {
      type: Boolean,
      default: false,
    },
    // 是否默认展开所有节点
    defaultExpandAll: {
      type: Boolean,
      default: false,
    },
    // 是否可过滤
    filterable: {
      type: Boolean,
      default: true,
    },
    // 过滤占位符文案
    filterPlaceholder: {
      type: String,
      default: '请输入',
    },
    // 过滤函数，参数为文本框输入值 value、节点原始数据 data、包装后的节点对象 node
    filterMethod: {
      type: Function,
      default: undefined,
    },
    // 是否高亮当前选中节点
    highlightCurrent: {
      type: Boolean,
      default: false,
    },
    // 是否在单击节点时展开或收起节点，若为 false 则只能通过单击箭头展开或收起
    expandOnClickNode: {
      type: Boolean,
      default: true,
    },
    // 树的最大高度，单位: px，0 或者空表示不限制最大高度
    maxHeight: {
      type: Number,
      default: 0,
    },
    // 作为 label 的 key
    labelKey: {
      type: String,
      default: 'label',
    },
    // 作为 value 的 key
    valueKey: {
      type: String,
      default: 'value',
    },
    // 作为 children 的 key
    childrenKey: {
      type: String,
      default: 'children',
    },
    // 作为节点 disabled 的 key
    disabledKey: {
      type: String,
      default: 'disabled',
    },
    // 是否禁用某一项的回调函数，参数为节点数据对象
    disabledCallback: {
      type: Function,
      default: undefined,
    },
    // 是否隐藏某一项的回调函数，参数为节点数据对象
    hiddenCallback: {
      type: Function,
      default: undefined,
    },
  },
};
